import { template as template_ec7638176c1f44d79043375e7cfe5999 } from "@ember/template-compiler";
const FKText = template_ec7638176c1f44d79043375e7cfe5999(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
