import { template as template_6e7f455d17b94ec0a93e5103fc071ad3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_6e7f455d17b94ec0a93e5103fc071ad3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
